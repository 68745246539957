export const topicRoute = [
    {
        path: 'topic',
        meta: {
            isShowMenu: false,
            auth: true,
            isDefaultWxInit: true,
            keepAlive: true,
            title: '专题'

        },
        component: () => import('@/pages/topic/index.vue')
    },
    {
        path: 'topic/detail/:id',
        name: 'topicDetail',
        meta: {
            isShowMenu: false,
            auth: false,
            keepAlive: false,
            title: ''
        },
        component: () => import('@/pages/topic/detail.vue')
    },
    {
        path: 'topic/edit',
        name: 'topicEdit',
        meta: {
            isShowMenu: false,
            auth: true,
            isDefaultWxInit: true,
            keepAlive: false,
            title: '编辑专题'
        },
        component: () => import('@/pages/topic/edit.vue')
    },
    {
        path: 'topic/test',
        name: 'topicTest',
        meta: {
            isShowMenu: false,
            auth: true,
            isDefaultWxInit: true,
            keepAlive: false,
                title: '专题测试'
        },
        component: () => import('@/pages/topic/test.vue')
    }
]