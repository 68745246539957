export const outerChatRoute = [

      {
        path: '/outerChat',
        meta: {
          isShowMenu: false,
          auth: false,
          isDefaultWxInit: true,
          keepAlive: false,
          title: ''
        },
        component: () => import('@/pages/outerChat')
      },
 
 
]