export const configRoute = [
    {
        path: 'config',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: true,
          title: '设置'
        },
        component: () => import('@/pages/config')
      },

      {
        path: 'config/promopterInfo',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: true,
          title: '账号设置'
        },
        component: () => import('@/pages/config/promopterInfo.vue')
      },
      {
        path: 'config/dialog',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: true,
          title: '对话设置'
        },
        component: () => import('@/pages/config/dialog.vue')
      },
      {
        path: 'config/notify',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: true,
          title: '消息通知'
        },
        component: () => import('@/pages/config/notify.vue')
      }
]