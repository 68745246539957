export const visitorRoute = [
    
    {
        path: 'visitor',
        meta: {
          isShowMenu: true,
          auth: true,
          activeNav: 'visitor',
          isDefaultWxInit: true,
          keepAlive: true,
          title: '访客'
        },
        component: () => import('@/pages/visitor/index.vue')
      },
      {
        path: 'visitor/detail/:id',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: false,
          title: ''
        },
        component: () => import('@/pages/visitor/detail.vue')
      }
]