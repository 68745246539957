import router from "@/router";
import moment from 'moment';
import { showNotify } from 'vant';
import { getTemporaryOpenidStatus, getOpenidStatus } from '@/plugins/cookie'
import { CommonEasyRing } from 'easy-ring'
import store from "@/store";
import notifyAudio from '@/assets/audio/new-message.mp3';
// import { getVisitorHistory } from '@/api/visitor';
// import { getLinkStatus } from '@/api/common';
import { emoj } from '@/utils/emnu';

export function initAudioNotify() {
    const myEasyring = new CommonEasyRing({
        src: notifyAudio,
        loop: false
    })

    function onClick() {
        myEasyring.open() // 1 开启组件

        document.removeEventListener('click', onClick)
    }

    document.addEventListener('click', onClick)
    return myEasyring
}
export function getCityByUrl() {
    let host = window.location.host;
    return import.meta.env.MODE === 'production' ? host.slice(0, host.search(/\./)) : import.meta.env.VITE_CITY;
}
export function randomNum(max, min) {
    const num = Math.floor(Math.random() * (max - min + 1)) + min
    return num
}
//判断ios
export function isIOSWeChat() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return true
    }
}
export function judgeTerminalType() {
    let deviceType = '';

    // 判断 iOS 设备
    if (/iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())) {
        deviceType = 'ios';
    }

    // 判断 Android 设备
    else if (/android/.test(navigator.userAgent.toLowerCase())) {
        deviceType = 'android';
    }

    // 判断平板设备 
    else if (/ipad|playbook/.test(navigator.userAgent.toLowerCase())) {
        deviceType = 'tablet';
    }

    // 判断手机设备
    else if (/mobile/.test(navigator.userAgent.toLowerCase())) {
        deviceType = 'mobile';
    }

    // 判断桌面设备
    else {
        deviceType = 'desktop';
    }
    return deviceType

}
export function getLastWeekTimeRange() {
    // 设置语言环境为中文，以符合通常的中国周一到周日的计算方式
    moment.locale('zh-cn');

    // 获取当前时间
    const now = moment();

    // 获取上周的开始和结束时间
    const lastWeekStart = now.clone().subtract(1, 'weeks').startOf('isoWeek');
    const lastWeekEnd = now.clone().subtract(1, 'weeks').endOf('isoWeek');
    // 将日期格式化为数组 ['年', '月', '日']
    const lastWeekStartArray = [
        lastWeekStart.format('YYYY'), // 年
        lastWeekStart.format('MM'),   // 月
        lastWeekStart.format('DD')    // 日
    ];

    const lastWeekEndArray = [
        lastWeekEnd.format('YYYY'), // 年
        lastWeekEnd.format('MM'),   // 月
        lastWeekEnd.format('DD')    // 日
    ];
    return [lastWeekStartArray, lastWeekEndArray]

}


export function judegeBrowserType() {
    var Sys = {};
    var ua = navigator.userAgent.toLowerCase();
    var s;
    (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :
        (s = ua.match(/msie ([\d\.]+)/)) ? Sys.ie = s[1] :
            (s = ua.match(/edge\/([\d\.]+)/)) ? Sys.edge = s[1] :
                (s = ua.match(/firefox\/([\d\.]+)/)) ? Sys.firefox = s[1] :
                    (s = ua.match(/(?:opera|opr).([\d\.]+)/)) ? Sys.opera = s[1] :
                        (s = ua.match(/chrome\/([\d\.]+)/)) ? Sys.chrome = s[1] :
                            (s = ua.match(/version\/([\d\.]+).*safari/)) ? Sys.safari = s[1] : 0;
    // 根据关系进行判断
    if (Sys.ie) return ('IE: ' + Sys.ie);
    if (Sys.edge) return ('EDGE: ' + Sys.edge);
    if (Sys.firefox) return ('Firefox: ' + Sys.firefox);
    if (Sys.chrome) return ('Chrome: ' + Sys.chrome);
    if (Sys.opera) return ('Opera: ' + Sys.opera);
    if (Sys.safari) return ('Safari: ' + Sys.safari);
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return '微信内置浏览器';
    } else {
        return 'Unkonwn';
    }

}
export const getText = (item) => {
    if (item.notificationType === 0) {
        let textList = item.text.split(',')
        return `${textList[0]}正在浏览《${textList[1]}》`
    } else if (item.notificationType === 1) {
        return '您有一个新的报名，点击前往“线索”查看>>'
    } else if (item.notificationType === 2) {
        return `${item.text}发来新的消息，点击前往对话>>`
    } else {
        return item.text
    }
}


export function validateTel(tel) {

    if (!tel || tel.length == 0) {

        return {
            state: false,
            tit: '手机号码不能为空',
            //confirmBtnText: '点击关闭'
        };
    }
    if (tel.length != 11) {

        return {
            state: false,
            tit: '请输入有效的手机号码，需是11位',
            // confirmBtnText: '点击关闭'
        };
    }


    if (!(/^1[3|4|5|6|7|8|9]\d{9}$/.test(tel))) {

        return {
            state: false,
            tit: '手机号码有误，请重新输入。',
            // confirmBtnText: '点击关闭'
        };
    }

    return {
        state: true,
        tit: '手机号验证通过',
        // confirmBtnText: '点击关闭'
    };
}
export function validateArea(area) {

    if (area === undefined || area === null) {
        return {
            state: true,
            tit: '面积验证通过',
            // confirmBtnText: '点击关闭'
        };
    } else {
        if (typeof area === 'number') {
            if (area.length > 5) {

                return {
                    state: false,
                    tit: '请输入小于5位有效面积数。',
                    // confirmBtnText: '点击关闭'
                };
            } else {
                return {
                    state: true,
                    tit: '面积验证通过',
                    // confirmBtnText: '点击关闭'
                };
            }
        } else {
            return {
                state: false,
                tit: '请输入有效的面积数字。',
                // confirmBtnText: '点击关闭'
            };
        }
    }


}


//验证QQ号码5-11位
//验证QQ号码5-11位
export function isQQ(qq) {
    var filter = /^\s*[.0-9]{5,11}\s*$/;
    if (!filter.test(qq)) {
        return false;
    } else {
        return true;
    }
}

export function judgeError(data) {
    if (data.errCode) {


        router.push({
            name: 'error404',
        })
    }
    if (location.search.includes('preview')) {
        return true;
    } else if (data.auditStatus !== 20 || !data.isPublish) {

        router.push({
            name: 'error404',
        })
    }

}
//判断元素是否在视口中
export const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect()
    const vWidth = window.innerWidth || document.documentElement.clientWidth
    const vHeight = window.innerHeight || document.documentElement.clientHeight


    if (
        rect.right < 0 ||
        rect.bottom < 0 ||
        rect.left > vWidth ||
        rect.top > vHeight
    ) {
        return false
    }

    return true
}


export function getUuid() {
    var s = [];
    var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";
    let uuid = s.join("");
    return uuid;
}


export function setTKD({ title, keywords, description } = {}) {
    document.title = title;
    document.getElementsByName('keywords')[0].content = keywords;
    document.getElementsByName('description')[0].content = description;
    store.commit('changeIsSetTkd', true);
}


export function stop() {//禁止滑动限制

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    //document.body.style.paddingRight = '17px'
    // var myEvent = new Event('resize');
    // window.dispatchEvent(myEvent);

}
export function move() {//取消滑动限制
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    //document.body.style.paddingRight = '0px'
    // var myEvent = new Event('resize');
    // window.dispatchEvent(myEvent);

}


export function checkOs() {
    var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),

        isTablet =
            /(?:iPad|PlayBook)/.test(ua) ||
            (isAndroid && !/(?:Mobile)/.test(ua)) ||
            (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc,
    };
}
export function scrollToLocation(x, y) {
    document.body.scrollTo({
        top: x,
        left: y
    });
    document.documentElement.scrollTo({
        top: x,
        left: y
    });
}
export function debounce(func, wait) {
    let timeout;
    return function() {
        const context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
// 非推广员百度统计
export  function addFilterStats(siteId) {

    (function () {
        var hm = document.createElement("script");
        hm.src = `https://hm.baidu.com/hm.js?${siteId}`;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();
}
// 百度统计初始化
export function initBaiduStats(isLogin) {
    // 百度统计site id
    const baiduSiteId = {
        statsAllSiteId: '654648ba8e7bde3ac139c6389edce3b0',
        filterPromotertSiteId: '0775ffe5f2b9976781359d192dd875b6'
    }

    var _hmt = window._hmt || [];



    window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
    // 百度统计代码引入1
    (function () {
        var hm = document.createElement("script");
        hm.src = `https://hm.baidu.com/hm.js?${baiduSiteId.statsAllSiteId}`;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();

    if (!isLogin) {
        // 添加没有登录则添加非推广员pv统计
        addFilterStats(baiduSiteId.filterPromotertSiteId);
    }

}
/**
 * 回调节流
 *
 * @export
 * @param {*} action 回调
 * @param {*} delay 等待的时间
 * @param {*} context this指针
 * @param {Boolean} iselapsed 是否等待上一次
 * @returns {Function}
 */
export function throttle(action, delay, context, iselapsed) {
    let timeout = null;
    let lastRun = 0;
    return function () {
        
        if (timeout) {
            if (iselapsed) {
                return;
            } else {
                clearTimeout(timeout);
                timeout = null;
            }
            // return;
        }
        let elapsed = Date.now() - lastRun;
        let args = arguments;
        if (iselapsed && elapsed >= delay) {
            runCallback();
        } else {
            timeout = setTimeout(runCallback, delay);
        }
        /**
         * 执行回调
         */
        function runCallback() {
            lastRun = Date.now();
            timeout = false;
            action.apply(context, args);
        }
    };
}

export function parseStringBySize(str, size) {
    if (str.length > size) {
        return str.slice(0, size) + '...'
    } else {
        return str;
    }

}
export function formatSeconds(seconds) {
    if (!seconds) {
        return '0秒'
    }
    // 使用 Moment.js 的 duration 函数处理秒数
    const duration = moment.duration(seconds, 'seconds');

    // 获取分钟和秒钟
    const minutes = duration.minutes();
    const remainingSeconds = duration.seconds();
    if (minutes > 0) {
        if (remainingSeconds > 0) {
            return `${minutes}分${remainingSeconds}秒`
        } else {

            return `${minutes}分钟`
        }
    } else {
        return `${remainingSeconds}秒`
    }

}
/**
 * Parse the time to string
 * @param {(Date|string|number)} time
 * @param {string} format
 * @returns {string}
 */
export function formatDateTime(dateTime, format) {
    if (arguments.length === 0) {
        return dateTime
    }
    if (!dateTime) { return ''; }
    if (!moment(dateTime).isValid()) { return dateTime; }

    const cFormat = format || 'YYYY-MM-DD HH:mm:ss'
    return moment(dateTime).format(cFormat);
}
/**
 * trim(str, pos)
 * 该方法可以去除空格，分别可以去除所有空格，两端空格，左边空格，右边空格，默认为去除两端空格
 * str <String> 字符串
 * pos <String> 去除那些位置的空格，可选为：both-默认值，去除两端空格，left-去除左边空格，right-去除右边空格，all-去除包括中间和两端的所有空格
*/
export function trimAll(str, pos = 'both') {
    if (pos == 'both') {
        return str.replace(/^\s+|\s+$/g, "");
    } else if (pos == "left") {
        return str.replace(/^\s*/, '');
    } else if (pos == 'right') {
        return str.replace(/(\s*$)/g, "");
    } else if (pos == 'all') {
        return str.replace(/\s+/g, "");
    } else {
        return str;
    }
}
export function splitArrayIntoGroups(array, sliceLength) {
    var result = [];
    for (var i = 0; i < array.length; i += sliceLength) {
        result.push(array.slice(i, i + sliceLength));
    }
    return result;
}

export function copyToClipboard(text) {
    const newCopyFun = () => {
        navigator.clipboard.writeText(text).then(
            function () {
                showNotify({ type: 'success', message: '复制成功' });
            },
            function () {
                var input = document.createElement("input");
                input.value = text;
                document.body.appendChild(input);
                input.select();
                input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
                document.body.removeChild(input);
                showNotify({ type: 'success', message: '复制成功' });
            }
        )
    }

    newCopyFun()

}
export function disableLinks(htmlString) {
    // 使用正则表达式查找所有的<a>标签，并移除href属性
    return htmlString.replace(/<a\s+(href="[^"]*")?([^>]*>)/g, function (match, p1, p2) {
        // 移除href属性，但保留其他属性和标签内容
        return '<a ' + p2;
    });
}


export function getParamsByUrl(url, param) {
    const myURL = new URL(url);
    const urlParams = new URLSearchParams(myURL.search);
    return urlParams.get(param)

}
export function removeLinks(html) {
    return html.replace(/<a[^>]*>(.*?)<\/a>/ig, "$1");
}//获取url中的code参数
export function get_url_param(name) {
    //封装方法
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; //返回参数值
}
//判断是否是安卓
export function isAndroid() {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1;
    if (isAndroid) {
        return true;
    } else {
        return false;
    }
}



/* 创建分公司对象 */
function CreateCompany(id, domain, name) {
    this.id = id;
    this.domain = domain;
    this.name = name;
}
/* 分公司列表处理 */
export var aboutCompany = {
    array: [ //数据来源于数据库 2019/02/14
        // new CreateCompany(1, 'bjhd2', '北京'),
        new CreateCompany(1, 'qd', '北京'),
        new CreateCompany(2, 'cixi', '慈溪'),
        new CreateCompany(3, 'zz', '郑州'),
        new CreateCompany(4, 'nb', '宁波'),
        new CreateCompany(5, 'xa', '西安'),
        new CreateCompany(6, 'tj', '天津'),
        new CreateCompany(7, 'gy', '贵阳'),
        new CreateCompany(9, 'lz', '兰州'),
        new CreateCompany(10, 'heb', '哈尔滨'),
        new CreateCompany(11, 'jn', '济南'),
        new CreateCompany(13, 'cqhd', '重庆'),
        new CreateCompany(14, 'sjz', '石家庄'),
        new CreateCompany(15, 'chengde', '承德'),
        new CreateCompany(16, 'baotou', '包头'),
        new CreateCompany(17, 'nantong', '南通'),
        new CreateCompany(18, 'dezhou', '德州'),
        new CreateCompany(19, 'jining', '济宁'),
        new CreateCompany(20, 'qd', '青岛'),
        new CreateCompany(21, 'zhenjiang', '镇江'),
        new CreateCompany(22, 'cd', '成都'),
        new CreateCompany(23, 'nanyang', '南阳'),
        new CreateCompany(24, 'nj', '南京'),
        new CreateCompany(26, 'nc', '南昌'),
        new CreateCompany(27, 'qhd', '秦皇岛'),
        new CreateCompany(28, 'hf', '合肥'),
        new CreateCompany(29, 'dl', '大连'),
        new CreateCompany(30, 'wh', '武汉'),
        new CreateCompany(31, 'tangshan', '唐山'),
        new CreateCompany(33, 'sy', '沈阳'),
        new CreateCompany(34, 'wlmq', '乌鲁木齐'),
        new CreateCompany(35, 'ty', '太原'),
        new CreateCompany(36, 'tongliao', '通辽'),
        new CreateCompany(37, 'wf', '潍坊'),
        new CreateCompany(39, 'qq', '齐齐哈尔'),
        new CreateCompany(40, 'shangqiu', '商丘'),
        new CreateCompany(42, 'lyg', '连云港'),
        new CreateCompany(44, 'kaifeng', '开封'),
        new CreateCompany(46, 'maanshan', '马鞍山'),
        new CreateCompany(50, 'weinan', '渭南'),
        new CreateCompany(52, 'xining', '西宁'),
        new CreateCompany(53, 'baoding', '保定'),
        new CreateCompany(54, 'suzhou', '苏州'),
        new CreateCompany(55, 'zibo', '淄博'),
        new CreateCompany(56, 'leshan', '乐山'),
        new CreateCompany(57, 'xuzhou', '徐州'),
        new CreateCompany(58, 'handan', '邯郸'),
        new CreateCompany(59, 'zhoukou', '周口'),
        new CreateCompany(61, 'jinzhou', '锦州'),
        new CreateCompany(63, 'kaili', '凯里'),
        new CreateCompany(66, 'xianning', '咸宁'),
        new CreateCompany(67, 'jiaozuo', '焦作'),
        new CreateCompany(69, 'pds', '平顶山'),
        new CreateCompany(70, 'huaian', '淮安'),
        new CreateCompany(71, 'linyi', '临沂'),
        new CreateCompany(72, 'yantai', '烟台'),
        new CreateCompany(73, 'renqiu', '任丘'),
        new CreateCompany(74, 'xsbn', '西双版纳'),
        new CreateCompany(77, 'cs', '长沙'),
        new CreateCompany(82, 'yibin', '宜宾'),
        new CreateCompany(83, 'hhht', '呼和浩特'),
        new CreateCompany(85, 'linxia', '临夏'),
        new CreateCompany(87, 'nanchong', '南充'),
        new CreateCompany(90, 'jingdezhen', '景德镇'),
        new CreateCompany(93, 'zigong', '自贡'),
        new CreateCompany(96, 'huangshi', '黄石'),
        new CreateCompany(97, 'fuzhou', '福州'),
        new CreateCompany(98, 'haikou', '海口'),
        new CreateCompany(100, 'suining', '遂宁'),
        new CreateCompany(102, 'huzhou', '湖州'),
        new CreateCompany(104, 'wuxi', '无锡'),
        new CreateCompany(106, 'hanzhong', '汉中'),
        new CreateCompany(107, 'jincheng', '晋城'),
        new CreateCompany(110, 'wlcb', '乌兰察布'),
        new CreateCompany(112, 'dongying', '东营'),
        new CreateCompany(114, 'xiantao', '仙桃'),
        new CreateCompany(115, 'cangzhou', '沧州'),
        new CreateCompany(120, 'sanya', '三亚'),
        new CreateCompany(121, 'xiangfan', '襄阳'),
        new CreateCompany(122, 'yining', '伊宁'),
        new CreateCompany(124, 'changde', '常德'),
        // new CreateCompany(125, 'jt', '集团'),
        new CreateCompany(125, 'qd', '集团'),
        new CreateCompany(126, 'duyun', '都匀'),
        new CreateCompany(127, 'yancheng', '盐城'),
        new CreateCompany(128, 'suqian', '宿迁'),
        new CreateCompany(130, 'zhangjiakou', '张家口'),
        new CreateCompany(131, 'foshan', '佛山'),
        new CreateCompany(132, 'luoyang', '洛阳'),
        new CreateCompany(134, 'anshun', '安顺'),
        new CreateCompany(135, 'liuan', '六安'),
        new CreateCompany(136, 'weihai', '威海'),
        new CreateCompany(137, 'changchun', '长春'),
        new CreateCompany(138, 'guangan', '广安'),
        new CreateCompany(140, 'klmy', '克拉玛依'),
        new CreateCompany(144, 'zhangye', '张掖'),
        new CreateCompany(146, 'changzhou', '常州'),
        new CreateCompany(147, 'nanning', '南宁'),
        new CreateCompany(148, 'anyang', '安阳'),
        new CreateCompany(149, 'pinde', '品德装饰'),
        new CreateCompany(151, 'macheng', '麻城'),
        new CreateCompany(154, 'ziyang', '资阳'),
        new CreateCompany(155, 'shiyan', '十堰'),
        new CreateCompany(156, 'dingxi', '定西'),
        new CreateCompany(157, 'hengshui', '衡水'),
        new CreateCompany(158, 'zhaotong', '昭通'),
        new CreateCompany(159, 'nttongzhou', '通州'),
        new CreateCompany(160, 'xingtai', '邢台'),
        // new CreateCompany(161, 'bdqby', '保定全包圆'),
        // new CreateCompany(162, 'bjqby', '北京全包圆'),
        new CreateCompany(161, 'qd', '保定全包圆'),
        new CreateCompany(162, 'qd', '北京全包圆'),
        new CreateCompany(163, 'chuxiong', '楚雄'),
        new CreateCompany(165, 'chaoyang', '朝阳'),
        new CreateCompany(166, 'jilin', '吉林'),
        new CreateCompany(167, 'jingzhou', '荆州'),
        new CreateCompany(168, 'guyuan', '固原'),
        new CreateCompany(169, 'yulin', '榆林'),
        new CreateCompany(170, 'shuyang', '沭阳'),
        new CreateCompany(171, 'xichang', '西昌'),
        new CreateCompany(172, 'lishui', '丽水'),
        // new CreateCompany(280, 'bjmz', '北京迷住')
        new CreateCompany(280, 'qd', '北京迷住'),
        new CreateCompany(288, 'lasa', '拉萨'),
        new CreateCompany(314, 'taizhou', '泰州'),
        new CreateCompany(321, 'panzhou', '盘州'),
        new CreateCompany(323, 'kashi', '喀什'),
        new CreateCompany(328, 'jiayuguan', '嘉峪关'),
        new CreateCompany(335, 'nantongjingzhuang', '南通精装'),
        new CreateCompany(341, 'guilin', '桂林'),
        new CreateCompany(336, 'heze', '菏泽')

    ],
    getByName: function (name) {
        return aboutCompany.array.find(item => {
            return item['name'] === name.replace('市', '');
        });
    },
    getById: function (id) {
        return aboutCompany.array.find(item => {
            return item['id'] === id;
        });
    },
    getByDomain: function (domain) {
        return aboutCompany.array.find(item => {
            return item['domain'] === domain;
        });
    }
};
/**
* 删除当前url中指定参数
* @param names 数组或字符串
* @returns {string}
*/
export function funcUrlDel(names) {
    if (typeof (names) == 'string') {
        names = [names];
    }
    var loca = window.location;
    var obj = {}
    var arr = loca.search.substr(1).split("&");
    //获取参数转换为object
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].split("=");
        obj[arr[i][0]] = arr[i][1];
    }
    //删除指定参数
    for (var j = 0; j < names.length; j++) {
        delete obj[names[j]];
    }
    //重新拼接url
    var url = loca.origin + loca.pathname + "?" + JSON.stringify(obj).replace(/["{}]/g, "").replace(/:/g, "=").replace(/,/g, "&");
    return url;
}

//判断是否是微信环境
export function getIsWxClient() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    }
    return false;
}
export function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}
export function getOpenidStatusByP(p) {

    // openid都是长期的，临时openid只是为了更新用信息
    // 判断是否含有p参数且p参数不等于当前推广员id。说明是推广员访问别人的链接
    if (p && p !== store.state.user.promopterInfo.userName) {
        // 从cookie中获取promopteropenidIsValid并返回
        return getTemporaryOpenidStatus();
    } else {
        // 从localStorage中获取promopteropenidIsValid并返回
        return getOpenidStatus();
    }
}

//判断一段字符串中是否包含 某段字符串，不区分大小写
export function coverString(subStr, str) {
    var reg = eval("/" + subStr + "/ig");
    return reg.test(str);
}
export function flatten(data, parentId = null) {
    let flatList = [];

    for (let item of data) {
        let children = item.children || [];
        delete item.children;
        let newItem = { ...item };
        newItem.parentId = parentId !== null ? parentId : item.parentId;
        flatList.push(newItem);
        if (children.length > 0) {
            flatList = flatList.concat(flatten(children, item.id));
        }
    }
    return flatList;
}

function formatDateToDateTime(date) {
    const y = date.getFullYear();
    const m = String(date.getMonth() + 1).padStart(2, '0');
    const d = String(date.getDate()).padStart(2, '0');
    const h = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const sec = String(date.getSeconds()).padStart(2, '0');

    return `${y}-${m}-${d} ${h}:${min}:${sec}`;
}

export function generateTimePeriods() {
    const now = new Date();

    // 今天的开始和结束
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

    // 昨天的开始和结束
    const yesterdayStart = new Date(todayStart);
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    const yesterdayEnd = new Date(todayEnd);
    yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);

    // 本周的开始和结束
    const weekStart = new Date(todayStart);
    weekStart.setDate(todayStart.getDate() - todayStart.getDay() + 1);
    const weekEnd = new Date(todayEnd);
    weekEnd.setDate(todayEnd.getDate() + (7 - todayEnd.getDay()));

    return {
        today: {
            start: formatDateToDateTime(todayStart),
            end: formatDateToDateTime(todayEnd)
        },
        yesterday: {
            start: formatDateToDateTime(yesterdayStart),
            end: formatDateToDateTime(yesterdayEnd)
        },
        thisWeek: {
            start: formatDateToDateTime(weekStart),
            end: formatDateToDateTime(weekEnd)
        }
    };
}

export function findItemWithParents(id, data) {
    // 新的递归函数，增加了parents参数来跟踪所有的父元素
    function recurse(items, parents) {
        for (let item of items) {
            // 当前元素的所有父元素的数组
            const newParents = [...parents, item];
            if (item.id == id) {
                // 返回包含所有父元素和当前元素的数组
                return newParents;
            }
            if (item.children) {
                // 递归调用，传递当前元素的所有父元素数组
                const found = recurse(item.children, newParents);
                if (found) {
                    return found;
                }
            }
        }
    }

    // 从顶层数据开始递归，顶层元素的父元素数组为空
    return recurse(data, []);
}



export function formatRelativeTime(inputTime) {

    const currentTime = new Date();
    const diffInMs = currentTime - new Date(inputTime);

    const sec = Math.floor(diffInMs / 1000);
    if (sec < 0) {
        return '0秒前';
    }
    if (sec < 60) {
        return sec + '秒前';
    }

    const min = Math.floor(diffInMs / 60000);
    if (min < 60) {
        return min + '分钟前';
    }

    const hr = Math.floor(diffInMs / 3600000);
    if (hr < 24) {
        return hr + '小时前';
    }

    const day = Math.floor(diffInMs / 86400000);
    if (day < 2) {
        return '昨天';
    }
    if (day < 3) {
        return '前天';
    }

    return new Date(inputTime).toLocaleDateString();
}

export function cancelRequest(key) {
    if (window.$httpRequestList.has(key)) {

        const source = window.$httpRequestList.get(key);
        source.cancel();
    }
}
export function lowerCaseKeys(obj) {
    if (typeof obj !== 'object') {
        return obj;
    }

    const newObj = {};

    for (let key in obj) {
        if (typeof obj[key] === 'object') {
            newObj[key.charAt(0).toLowerCase() + key.slice(1)] = lowerCaseKeys(obj[key]);
        } else {
            newObj[key.charAt(0).toLowerCase() + key.slice(1)] = obj[key];
        }
    }

    return newObj;
}
export function replaceTextWithEmoj(text) {
    if (!text) return;
    let replacedText = text;

    for (let i = 0; i < emoj.length; i++) {
        const item = emoj[i];
        const escapedName = item.name.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

        const regExp = new RegExp(escapedName, "g");

        // 确保替换的文本不会导致无限循环
        if (item.name !== item.src && item.src.indexOf(item.name) === -1) {
            const imgTag = `<img src="${item.src}" alt="${item.name}" style='width: 18px;height: 18px;margin-bottom:-3px;'>`;
            replacedText = replacedText.replace(regExp, imgTag);
        }
    }

    return replacedText;
}
export function setPageTitleByScroll(title) {
    return throttle(() => {
        var t = document.documentElement.scrollTop || document.body.scrollTop;

        if (t > 50) {
            document.title = title;
        } else {
            document.title = '\u200E';
        }
    }, 100, this, true)

}