export const promotionRoute = [
    {
        path: 'promotion',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: true,
          title: '推广'
        },
        component: () => import('@/pages/promotion/index.vue')
      },
      {
        path: 'promotion/detail/:id',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: false,
          title: '推广详情'
        },
        component: () => import('@/pages/promotion/detail.vue')
      }
]