export const spmRoute = [

    {
        path: 'spm/zztj_zxbjg',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: true,
            title:'快速获取装修报价'

        },
        component: () => import('@/pages/spm/zztj_zxbjg')
    },
    {
        path: 'spm/yzf_hbsj',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: true,
            title:'限量征集环保设计样板间'

        },
        component: () => import('@/pages/spm/yzf_hbsj')
    },
    {
        path: 'spm/yzf1_cpbj',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: true,
            title:'省钱省心的一站式装修服务'

        },
        component: () => import('@/pages/spm/yzf1_cpbj')
    },
    {
        path: 'spm/zzxn_zjlzybj',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: true,
            title:'限量征集蓝钻工艺样板间'

        },
        component: () => import('@/pages/spm/zzxn_zjlzybj')
    },

]