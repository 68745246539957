// 准备将来替代wxsdk组件的，纯js版本，暂时没启用
import { isAndroid, getIsWxClient, coverString, getUuid, get_url_param } from '@/utils/utils'

import { showFailToast } from 'vant';
import store from '@/store'
import wx from 'weixin-js-sdk';
import { getWxJsdk } from '@/api/common'



async function wxInit(option) {

    if (!getIsWxClient()) {
        showFailToast('不是微信环境')

        return
    }

    if (!option.config) {
        showFailToast("没有传递config参数")
        return
    }
    let params = {
        url: window.location.href.split('#')[0]
    }
    // IOS
    if (!isAndroid()) {
        params.url = store.state.wxjsdkUrl
    }
    console.log(window.location.href.split('#')[0])
    let res = await getWxJsdk(params).catch(e => {
        console.log(e)
    })

    if (!res) {
        return
    }
    wx.config({
        debug: false, // 是否开启调试模式
        appId: res.appId, //appid
        timestamp: res.timestamp, // 时间戳
        nonceStr: res.nonceStr, // 随机字符串
        signature: res.signature, // 签名
        jsApiList: [
            'updateAppMessageShareData', //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
            'updateTimelineShareData', //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
            'hideMenuItems', // 隐藏复制链接按钮
            'checkJsApi',
            'onMenuShareTimeline',//转发朋友圈
            'onMenuShareAppMessage',//转发好友
            'onMenuShareQQ',//转发QQ
            'onMenuShareWeibo',//转发微博
            'onMenuShareQZone',//转发空间
            'hideAllNonBaseMenuItem',
            'showMenuItems',
            'showAllNonBaseMenuItem',
            'wx-open-launch-weapp'
        ], // 需要使用的JS接口列表
        openTagList: [
            'wx-open-launch-weapp'
        ] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
    })


    wx.ready(function () {
        console.log('微信jsdk成功');

        if (option.config.isHidden) {
            wx.hideAllNonBaseMenuItem();
            wx.hideMenuItems({
                menuList: [
                    'menuItem:copyUrl',
                    'menuItem:share:appMessage',
                    'menuItem:share:timeline',
                    "menuItem:favorite"
                ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3

            });
        } else {
            wx.showAllNonBaseMenuItem();
            wx.hideMenuItems({
                menuList: [
                    'menuItem:copyUrl',
                    "menuItem:favorite"
                ]// 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
            });
        }
        console.log(option)
        var obj = {
            title: option.config.title, // 分享标题
            link: option.config.link, // 分享链接
            desc: option.config.desc, // 分享描述
            imgUrl: option.config.imgUrl || 'https://yenova-picfw.oss-cn-qingdao.aliyuncs.com/shadow/20190620153242.png', // 分享图标
            success: function (res) {
                console.log(res.errMsg)


                //微信转发bug，必须放在计时器内才执行
                setTimeout(function () {

                    //添加转发表
                    if (coverString('AppMessage', res.errMsg)) {

                        if (store.state.user.promopter) {
                            store.state.user.promopter.shareFriends()
                            store.commit('user/CHANGE_HISTORY_ID', getUuid())
                            store.commit('user/CHANGE_SHARE_LINK', window.location.origin + window.location.pathname + '?p=' + store.state.user.promopterInfo.userName + '&uuid=' + store.state.user.historyId)
                        } else if (store.state.user.visitor) {
                            store.state.user.visitor.shareFriends()
                            store.commit('user/CHANGE_HISTORY_ID', getUuid())
                            store.commit('user/CHANGE_SHARE_LINK', window.location.origin + window.location.pathname + '?p=' + get_url_param('p') + '&uuid=' + get_url_param('uuid') + '&vshId=' + store.state.user.historyId)
                        }
                    }
                    // 统计朋友圈转发

                    if (coverString('Timeline', res.errMsg)) {
                        if (store.state.user.promopter) {
                            store.state.user.promopter.shareMoments()
                            store.commit('user/CHANGE_HISTORY_ID', getUuid())
                            store.commit('user/CHANGE_SHARE_LINK', window.location.origin + window.location.pathname + '?p=' + store.state.user.promopterInfo.promopterInfo + '&uuid=' + store.state.user.historyId)
                        } else if (store.state.user.visitor) {
                            store.state.user.visitor.shareMoments()
                            store.commit('user/CHANGE_HISTORY_ID', getUuid())
                            store.commit('user/CHANGE_SHARE_LINK', window.location.origin + window.location.pathname + '?p=' + get_url_param('p') + '&uuid=' + get_url_param('uuid') + '&vshId=' + store.state.user.historyId)
                        }
                    }
                    store.commit('CHNAGE_WX_CONFIG', {
                        link: store.state.user.shareLink
                    })

                }, 500)
            },
            cancel: function () {
                // console.log('失败')
            }
        }
        //1.4.0新接口，没有成功后的回调
        // wx.updateAppMessageShareData(obj, function (res) {
        //     console.log(1111, res)
        // });
        // wx.updateTimelineShareData(obj, function (res) {
        //     console.log(2222, res)
        // });

        //微信即将废弃
        wx.onMenuShareAppMessage(obj);
        wx.onMenuShareTimeline(obj);
        wx.onMenuShareQQ(obj);
        wx.onMenuShareWeibo(obj);
        wx.onMenuShareQZone(obj);
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
    });



    wx.error(function (err) {
        // console.log(err)
        console.log('微信jsdk失败', err);
       // showFailToast("初始化微信JSDK失败")
        // if (!that.test) {
        // window.location.assign(window.location.href)
        // }


        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });





}


export {
    wxInit
}
