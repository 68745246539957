export const clueRoute = [
    {
        path: 'clue',
        meta: {
            isShowMenu: true,
            auth: true,
            activeNav: 'clue',
            isDefaultWxInit: true,
            keepAlive: true,
            title: '线索'
        },
        component: () => import('@/pages/clue/index.vue')
    },
    {
        path: 'clue/detail/:id',
        meta: {
            isShowMenu: false,
            auth: true,
            isDefaultWxInit: true,
            keepAlive: false,
            title: ''
        },
        component: () => import('@/pages/clue/detail.vue')
    }
]