import request from "./request"
export function getChatRecords() {
    return request({
        url: '/api/website/chatRecords-admin/main',
        method: 'get'
    })
}
export function getClueInfo() {
    return request({
        url: '/api/website/clues-admin/main',
        method: 'get'

    })
}
export function getVisitorInfo() {
    return request({
        url: '/api/website/shadowClients-admin/main',
        method: 'get'

    })
}
export function getTotalUserInfo() {
    return request({
        url: '/api/website/chatRecords-admin/total',
        method: 'get'

    })
}