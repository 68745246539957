
import { createStore } from 'vuex';

import { wxInit } from '@/utils/wxJsdk';
import chat from './modules/chat';
import homeInfo from './modules/homeInfo';
import axios from 'axios';
import user from './modules/user';
import registry from './modules/registry';
// 创建一个新的 store 实例
const store = createStore({
  state() {
    return {
      oauth2: 'https://oauth2.yzf.com.cn',
      loading: false,
      isShowMenu: true,
      activeNav: 'home',


      wxjsdkUrl: '', //为ios，微信jsdk授权准备的url
      wxConfig: {
        title: '', // 分享标题
        link: '', // 分享链接
        desc: '', // 分享描述
        imgUrl: '', // 分享图标
        isHidden: false
      }
    }
  },
  mutations: {


    CHANGE_ACTIVE_NAV(state, value) {
      state.activeNav = value
    },
    CHANGE_ISCHANGE_LOADING_MENU(state, value) {
      state.isShowMenu = value
    },

    CHANGE_Wxjsdk_URL(state, data) {
      state.wxjsdkUrl = data
    },
    CHNAGE_WX_CONFIG(state, data) {
      // 获取 data 的自身可枚举属性
      const dataPropertyNames = Object.getOwnPropertyNames(data);

      // 遍历 dataPropertyNames
      for (const propertyName of dataPropertyNames) {
        // 如果 wxConfig中也有相同的属性
        if (propertyName in state.wxConfig) {
          // 更新 wxConfig 对应属性的值
          state.wxConfig[propertyName] = data[propertyName];
        }
      }
      console.log('初始化wxjsdk', state.wxConfig)
      wxInit({
        config: state.wxConfig
      })
    },

    signOut() {
      // 清除本地存储
      localStorage.clear();

      // 清除会话存储
      sessionStorage.clear();
      // 清除所有cookie
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      // 强制刷新页面
      location.reload(true);
    },
    CHANGE_LOADING(state, data) {
      state.loading = data;
    }

  },
  actions: {

  },
  modules: {
    chat,
    homeInfo,
    user,
    registry
  }
})

export default store