import Cookies from 'js-cookie';

const TokenKey = 'Authorization'


export function getToken() {
    return localStorage.getItem(TokenKey)
}
export function getTokenKey() {
    return TokenKey
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    localStorage.removeItem(TokenKey)
}

export function getLoginTel() {
    return localStorage.getItem('loginTel')
}

export function setLoginTel(loginTel) {
    return localStorage.setItem('loginTel', loginTel)
}

export function removeLoginTel() {
    localStorage.removeItem('loginTel')
}

export function getLoginCountDown() {
    return Cookies.get('loginCountDown')
}

export function setLoginCountDown(loginCountDown) {
  
    return Cookies.set('loginCountDown', loginCountDown, {
        expires: 1 / 24 / 60
    })
}

export function removeLoginCountDown() {
    localStorage.removeItem('loginCountDown')
}

export function getVisitCount() {
    return localStorage.getItem('visitCount')
}

export function setVisitCount(visitCount) {
    return localStorage.setItem('visitCount', visitCount)
}

export function getIsLogin() {
    return Cookies.get('isLogin')
}

export function setIsLogin(isLogin, config) {
    return Cookies.set('isLogin', isLogin, config)
}

export function getOldUrl() {
    return localStorage.getItem('oldUrl')
}

export function setOldUrl(oldUrl) {
    return localStorage.setItem('oldUrl', oldUrl)
}

export function getVisitorOpenid() {
    return localStorage.getItem('visitoropenid')
}
export function setPanoramaLog(oldUrl) {
    console.log('shezhi', oldUrl)
    return sessionStorage.setItem('panoramaLog', oldUrl)
}

export function getPanoramaLog() {
    return sessionStorage.getItem('panoramaLog')
}

export function setVisitorOpenid(visitoropenid) {
    return localStorage.setItem('visitoropenid', visitoropenid)
}

export function getTemporaryOpenidStatus() {

    return Cookies.get('openidStatus')
}

export function setTemporaryOpenidStatus(OpenidStatus) {
    return Cookies.set('openidStatus', OpenidStatus, { expires: 3, path: '/' })
}
export function getOpenidStatus() {

    return localStorage.getItem('openidStatus')
}

export function setOpenidStatus(OpenidStatus) {
    return localStorage.setItem('openidStatus', OpenidStatus)
}


export function getPromopterInfoCache() {
    var cachePromopterInfo = localStorage.getItem('PromopterInfo') && JSON.parse(localStorage.getItem('PromopterInfo'));
    const props = ["avatar", "userName", "name", "organization", "phoneNumber", "wechatQrcode", "qq", "id", "tenantName", "tenantId","openId","city"];

    if (cachePromopterInfo) {
        var hasAllProps = true;
        const objKeys = Object.keys(cachePromopterInfo);
        for (const prop of props) {
            if (!objKeys.includes(prop)) {
                hasAllProps = false;
                break;
            }
        }
        return hasAllProps ? cachePromopterInfo : {
            "avatar": "",
            "userName": "",
            "name": "",
            "organization": "",
            "phoneNumber": "",
            "wechatQrcode": "",
            "qq": "",
            "id": "",
            "tenantName": '',
            "tenantId": '',
            "openId":'',
            "city":''
        }
    } else {
        return {
            "avatar": "",
            "userName": "",
            "name": "",
            "organization": "",
            "phoneNumber": "",
            "wechatQrcode": "",
            "qq": "",
            "id": "",
            "tenantName": '',
            "tenantId": '',
            "openId":'',
            "city":''
        }
    }

}
export function setPromopterInfoCache(PromopterInfo) {
    return localStorage.setItem('PromopterInfo', PromopterInfo && JSON.stringify(PromopterInfo))
}


export function getVisitorCommonInfoCache() {
    var cacheVisitorCommonInfo = localStorage.getItem('VisitorCommonInfo') && JSON.parse(localStorage.getItem('VisitorCommonInfo'));
    // const props = ["openId", "ip", "ipAttribution", "operator", "nickName", "sex", "language", "city", "province", "country", "unionid"];

    return cacheVisitorCommonInfo || {}
}

export function setVisitorCommonInfoCache(VisitorCommonInfo) {
    console.log('缓存中的commoninfo', VisitorCommonInfo)
    return localStorage.setItem('VisitorCommonInfo', VisitorCommonInfo && JSON.stringify(VisitorCommonInfo))
}

export function getVisitorPrivateInfoCacheByPromoterId(_id) {
    var cacheVisitorPrivateInfo = {};
    try {
        cacheVisitorPrivateInfo = localStorage.getItem('VisitorPrivateInfo' + _id) && JSON.parse(localStorage.getItem('VisitorPrivateInfo' + _id));
    } catch (e) {
        console.log(e)
    }
    return cacheVisitorPrivateInfo
}

export function setVisitorPrivateInfoCacheByPromoterId(_id, VisitorPrivateInfo) {
    return localStorage.setItem('VisitorPrivateInfo' + _id, JSON.stringify(VisitorPrivateInfo))
}

export function setUserCallCache(callFuns) {

    sessionStorage.setItem('promopterCallCache', callFuns)
}
export function getUserCallCache() {
    return sessionStorage.getItem('promopterCallCache')
}

export function setRegistryInfo(info) {
    return localStorage.setItem('registryInfo', JSON.stringify(info))
}
export function getRegistryInfo() {
    return localStorage.getItem('registryInfo') && JSON.parse(localStorage.getItem('registryInfo'))
}