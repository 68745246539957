export const messageRoute = [
    {
        path: 'message',
        meta: {
          isShowMenu: true,
          auth: true,
          activeNav: 'message',
          isDefaultWxInit: true,
          keepAlive: true,
          title: '消息'
        },
        component: () => import('@/pages/message/index.vue')
      },
      {
        path: 'message/notify',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: false,
          title: '通知'
        },
        component: () => import('@/pages/message/notify.vue')
      },
      {
        path: 'message/visitorChatDetail',
        meta: {
          isShowMenu: false,
          auth: false,
          isDefaultWxInit: true,
          keepAlive: false,
          title: ''
        },
        component: () => import('@/pages/message/visitorChatDetail.vue')
      },
     
      {
        path: 'message/promopterChatDetail',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: false,
          title: ''
        },
        component: () => import('@/pages/message/promopterChatDetail.vue')
      }
 
]