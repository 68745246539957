export const loginRoute = [
    {
        path: 'login',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: true,
            title: '登录'
        },
        component: () => import('@/pages/login/index.vue')
    },
    {
        path: 'loginByPhone',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: true,
            title: '登录'
        },
        component: () => import('@/pages/login/index_old.vue')
    },
    {
        path: 'recover',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: true,
            title: '找回账号'
        },
        component: () => import('@/pages/login/recover.vue')
    },
    {
        path: 'registry',
        name: 'registry',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: true,
            title: '账号注册'
        },
        component: () => import('@/pages/registry/index.vue')
    }

]