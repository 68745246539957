import request from "./request"
export function getMessageList(query) {
  return request({
    url: '/api/website/chatRecords-admin',
    method: 'get',
    params: query
  })
}
export function getMessagePromopterHistory(query) {
  return request({
    url: '/api/website/chatMessages-admin',
    method: 'get',
    params: query
  })
}
export function getMessageVisitorHistory(query) {
  return request({
    url: '/api/website/chatMessages',
    method: 'get',
    params: query
  })
}
export function getNotifiyList(query) {
  return request({
    url: '/api/website/notifications-admin',
    method: 'get',
    params: query
  })
}

export function wholeReadNotify(params) {

  return request({
      url: `/api/website/notifications-admin/Reads`,
      method: 'put',
      data:params

  })
}

export function getChatDetail(id) {
  return request({
    url: `/api/website/chatRecords-admin/${id}/for-edit`,
    method: 'get'

  })
}
export function getNotifyUnredCount() {
  return request({
    url: `/api/website/notifications-admin/UnRead`,
    method: 'get'

  })
}
export function readNotifiy(data) {
  return request({
    url: '/api/website/notifications-admin',
    method: 'put',
    data
  })
}
export function readMessage(data) {
  return request({
    url: '/api/website/chatMessages',
    method: 'put',
    data
  })
}