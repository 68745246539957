import * as signalR from '@microsoft/signalr';
import { getToken, getIsLogin } from '@/plugins/cookie';
import { getUuid, initAudioNotify, lowerCaseKeys } from '@/utils/utils'
import { getNotifyConfig } from '@/api/common';
import store from '@/store';
import moment from 'moment';
export function chatHub() {
    // 此处建立推广员的长链接，因为不光对话有使用，全局都有使用，所以在全局建立链接，相反访客端只需要在对话详情建立链接即可
    if (getIsLogin() !== '1') {
        return
    }
    

    const ring = initAudioNotify();
    // 获取通知配置，用来判断当有新消息、新对话通知时是否提示音提示
    getNotifyConfig().then(res => {
        store.commit('chat/CHNAGE_NOTIFY_CONFIG', res);

    })
    var connection = new signalR.HubConnectionBuilder()
        .withUrl("https://yzapi.yzf.com.cn/hub", {
            accessTokenFactory: () => getToken().replace('Bearer ', ''),
            headers: {
                Authorization: "{Authorization}"
            },
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .build();
    connection.start().then(() => {
        console.log('chatHub start')
        connection.invoke('OnConnectedAsync', {

        })
        if (store.state.chat.catchEvent.length > 0) {
            store.state.chat.catchEvent.forEach(item => {
                connection.invoke(item.fun, item.arguments)
            })
            // 请求后制空
            store.commit('chat/CHNAGE_CACHE_EVENT', [])

        }
    });
    connection.on('receiveMessage', (e) => {
        const res = lowerCaseKeys(JSON.parse(e));
        console.log('chatHub 收到信息')
        // 新消息提示音 当前未激活的对话来新消息则提示
        if (store.state.chat.activeRecord !== res.chatRecordId && store.state.chat.notifyConfig.newChatMessageSound) {
            console.log('ring')
            ring.ring();
        }
        // 新消息也要更新首页信息
        store.dispatch('homeInfo/updateHomeChat')
        store.dispatch('homeInfo/updateHomeUserInfo')
    })
   
    connection.on('receiveNotification', (e) => {
        console.log(e)
        // 目前通知有4种：访客来访、报名、访客浏览、新内容推送
        const res = JSON.parse(e);

        const notify = lowerCaseKeys(res);
        // 访客来访
        if (notify.notificationType === 0) {
            store.dispatch('homeInfo/updateHomeVisitor')
            store.dispatch('homeInfo/updateHomeUserInfo')
        }
        // 新报名
        if (notify.notificationType === 1) {
            store.dispatch('homeInfo/updateHomeClue')
            store.dispatch('homeInfo/updateHomeUserInfo')
        }
        // 新对话提示音
        if (notify.notificationType === 2 && store.state.chat.notifyConfig.newChatRecordSound) {
            store.dispatch('homeInfo/updateHomeChat')
            store.dispatch('homeInfo/updateHomeUserInfo')
        }


       
        // 这个notify 是在消息列表显示最新的通知内容
        store.commit('chat/CHNAGE_NOTIFY', notify)
        // 当unreadNotiy 不是undefined 说明已经拉取过通知数了，此时收到通知则直接数量+1即可。
        if (store.state.chat.unReadNotfiy !== undefined) {
            store.commit('chat/CHANGE_UN_READ_NOTIFY', store.state.chat.unReadNotfiy + 1)
        }

    })
    // 把链接保存在全局，以便后续使用
    store.commit('chat/CHNAGE_CHAT_CONNECTION', connection)

}
// class CONNECTION{
//     constructor(){

//     }
//     OnConnectedAsync(){

//     }
//     ActiveChatAsync(){

//     }
//     receiveMessage(){

//     }
//     onLine(){

//     }
//     disconnected(){

//     }
//     receiveInputting(){

//     }
//     ReceiveRead(){

//     }
//     SendMessageGroup(){

//     }
//     SendMessage(){

//     }
//     ReadAsync(){

//     }
// }
class AUTOREPLAY {
    constructor(messages) {
        this.handles = [];
        messages.forEach(item => {
            if (item.isEnable) {
                let timeHandle = setTimeout(() => {
                    console.log('自动回复', item)
                    let cacheInfomation = store.state.chat.preMessage.find(cache => {
                        return item.infomation === cache.infomation
                    })
                    if (cacheInfomation) {
                        console.log('重复信息')
                    } else {
                        console.log('存入信息')
                        // 预设的信息需要自己添加发送时间
                        item.creationTime = moment().format();
                        // 对接消息字段，消息中的内容key
                        item.message = item.infomation;
                        // 推广消息 side 1 表示 推广员端的消息
                        item.side = 1;
                        item.id=getUuid();
                        // 预设消息默认未读
                        item.shadowClientReadState = false;
                        // 0 表示正常对话信息
                        item.chatType = 0;
                        store.commit('chat/CHANGE_PRE_MESSAGE', [...store.state.chat.preMessage, item])
                        // 设置最新消息，用于气泡展示
                        store.commit('chat/CHANGE_NEWMESSAGE', item)
                    }
                }, item.waittingTime * 1000)
                this.handles.push(timeHandle);
            }
        })
    }
    stopReplay() {
        console.log('关闭自动回复')
        this.handles.forEach(item => {
            clearTimeout(item)
        })
    }
}
export function initReplay(messages) {
    store.commit('chat/CHANGE_REPLAY_INSTANCE', new AUTOREPLAY(messages))
}
