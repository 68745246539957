import { getChatRecords, getClueInfo, getVisitorInfo, getTotalUserInfo } from '@/api/home'
import { getNotifiyList } from "@/api/message";
const state = {
    homeVisitor: {},
    homeClue: {},
    homeChat: {},
    loadingVisitor: true,
    loadingClue: true,
    loadingChat: true,
    homeUserInfo: {
        "avatar": "",
        "name": "",
        "orgnization": "",
        "id": "",
        "clue": 0,
        "pv": 0,
        "rc": 0,
        "promopterHis": 0
    },
}

const mutations = {
    CHANGE_LOADING_VISITORY(state, value) {
        state.loadingVisitor = value
    },
    CHANGE_LOADING_CLUE(state, value) {
        state.loadingClue = value
    },
    CHANGE_LOADING_CHAT(state, value) {
        state.loadingChat = value
    },
    CHANGE_HOME_USER_INFO(state, value) {
        state.homeUserInfo = value
    },
    CHANGE_HOME_VISITOR(state, value) {
        state.homeVisitor = value
    },
    CHANGE_HOME_CLUE(state, value) {
        state.homeClue = value
    },
    CHANGE_HOME_CHAT(state, value) {
        state.homeChat = value
    },
}

const actions = {
    // 这个是首页的promopterinfo 包括了pv uv 线索数
    updateHomeUserInfo(context) {

        return getTotalUserInfo().then(res => {


            context.commit('CHANGE_HOME_USER_INFO', res)

        })
    },
    updateHomeChat(context) {
        context.commit('CHANGE_LOADING_CHAT', true)
        return Promise.all([
            getNotifiyList({
                NotificationType: 2,
                Read: false,
                Sorting: 'CreationTime desc',
                MaxResultCount: 1
            }).then(res => {
                let temp = res.items && res.items[0] || {}
                temp.count = res.totalCount || undefined

                return temp.count

            }),
            getNotifiyList({
                NotificationType: 2,
                Sorting: 'CreationTime desc',
                MaxResultCount: 1
            }).then(res => {
                let temp = res.items && res.items[0] || {}

                // context.commit('CHANGE_HOME_CHAT', temp)
                return temp

            })
        ]).then(res => {

            let temp = res[1] || {}
            temp.count = res[0];
            context.commit('CHANGE_HOME_CHAT', temp)
        }).finally(() => {
            context.commit('CHANGE_LOADING_CHAT', false)
        })
    },
    updateHomeClue(context) {
        context.commit('CHANGE_LOADING_CLUE', true)
        return Promise.all([
            getNotifiyList({
                NotificationType: 1,
                Read: false,
                Sorting: 'CreationTime desc',
                MaxResultCount: 1
            }).then(res => {
                let temp = res.items && res.items[0] || {}
                temp.count = res.totalCount || undefined

                return temp.count

            }),
            getNotifiyList({
                NotificationType: 1,
                Sorting: 'CreationTime desc',
                MaxResultCount: 1
            }).then(res => {
                let temp = res.items && res.items[0] || {}

                // context.commit('CHANGE_HOME_CHAT', temp)
                return temp

            })
        ]).then(res => {

            let temp = res[1] || {}
            temp.count = res[0];
            context.commit('CHANGE_HOME_CLUE', temp)

        }).finally(() => {
            context.commit('CHANGE_LOADING_CLUE', false)
        })


    },
    updateHomeVisitor(context) {
        context.commit('CHANGE_LOADING_VISITORY', true)


        return Promise.all([
            getNotifiyList({
                NotificationType: 0,
                Read: false,
                Sorting: 'CreationTime desc',
                MaxResultCount: 1
            }).then(res => {
                let temp = res.items && res.items[0] || {}
                temp.count = res.totalCount || undefined

                return temp.count

            }),
            getNotifiyList({
                NotificationType: 0,
                Sorting: 'CreationTime desc',
                MaxResultCount: 1
            }).then(res => {
                let temp = res.items && res.items[0] || {}

                // context.commit('CHANGE_HOME_CHAT', temp)
                return temp

            })
        ]).then(res => {

            let temp = res[1] || {}
            temp.count = res[0];
            context.commit('CHANGE_HOME_VISITOR', temp)

        }).finally(() => {
            context.commit('CHANGE_LOADING_VISITORY', false)
        })


    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

