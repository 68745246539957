export const panoramaRoute = [
    {
        path: 'panorama',
        name: 'panorama',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: true,
          title: '3D全景'
        },
        component: () => import('@/pages/panorama/index.vue')
      },
      {
        path: 'panorama/edit',
        meta: {
          isShowMenu: false,
          auth: true,
          isDefaultWxInit: true,
          keepAlive: false,
          title: ''
        },
        component: () => import('@/pages/panorama/edit.vue')
      },
      {
        path: 'panorama/detail/:id',
        name: 'panoramaDetail',
        meta: {
          isShowMenu: false,
          auth: false,
          keepAlive: false,
          title: ''
        },
        component: () => import('@/pages/panorama/detail')
      }
      
]