export const homeRoute = [
    {
        path: '',
        meta: {
            isShowMenu: true,
            auth: true,
            activeNav: 'home',
            isDefaultWxInit: true,
            title: '我的工作台',
            keepAlive: true

        },
        component: () => import('@/pages/home/index.vue')
    }
]