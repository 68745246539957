import axios from 'axios'
import { getTokenKey, getToken } from '@/plugins/cookie'


let baseURL =process.env.NODE_ENV==='development'?'': process.env.VUE_APP_BASE_API;

// create an axios instance
const service = axios.create({
    baseURL: baseURL, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        addXRequestedWithHeader(config.headers);
        addAuthorizationHeaders(config.headers);
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

function addXRequestedWithHeader(headers) {
    if (headers) {
        headers['X-Requested-With'] = 'XMLHttpRequest';

    }
}

function addAuthorizationHeaders(headers) {
    const token = getToken() || '';
    if (token && headers) {
        headers[getTokenKey()] = token;
    }
}


// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {

        // console.log('status: ', response.status)
        // console.log('name: ', response.config.url.replace(response.config.baseURL, ''))
        // console.log('method: ', response.config.method)
        const data = response.data


        if (data) {
            return handleResponse(data)
        }

        return data
    },
    error => {


        if (error.response) {

            // 请求已发出，服务器返回的 http 状态码不是 2xx，例如：400，500，

            console.log(error.response.data && error.response.data.error && error.response.data.error.message)


            // console.log(error.response)
        } else if (error.request) {
            // 请求已发出，但没有收到响应，例如：断网，
            error.statusText = 'network Error'
            console.log('error: 请求已发出，但没有收到响应')
            // Toast.fail({

            //     message: error.message
            // });
            // console.log(error.request)
        } else {
            // 请求被取消或者发送请求时异常，
            // console.log('error', error.message)
        }

        console.log('request', error)





        return Promise.reject(error)
    }
)

function handleResponse(data) {
    if (data) {
        // console.log(data)
        if (data.Success == true) {
            // console.log(data)
            return data
        } else {
            // console.log(data)
            // Toast.fail({

            //     message: data.Message ? data.Message : data.Errors
            // });

            return data
        }
    } else {

        console.error('未知错误')

    }
}

export default service