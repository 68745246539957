


import { createApp } from 'vue'
import App from '@/App.vue'
// import { beginGlobelDomFontResize } from './utils/dom'
import router from "@/router";
import "@/assets/css/reset.css"
import 'animate.css/animate.min.css'
// Toast
// 引入模块后自动生效
import '@vant/touch-emulator';

import 'vant/es/toast/style';

// Dialog

import 'vant/es/dialog/style';

// Notify

import 'vant/es/notify/style';

// ImagePreview

import 'vant/es/image-preview/style';

import { getIsLogin } from "@/plugins/cookie"
import { initBaiduStats } from "@/utils/utils"
import { chatHub } from '@/utils/chatHub'
import store from '@/store/index.js'
import VConsole from 'vconsole'
import VueHtml5Editor from '@/assets/js/vue-html5-editor'
import opt from '@/utils/initHTMLEditor';


if (window.location.search.includes('debugger') || sessionStorage.getItem('isDebugger') === '1') {
    sessionStorage.setItem('isDebugger', '1')
    new VConsole();
}
initBaiduStats(getIsLogin() === '1')

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueHtml5Editor, opt);


// 用来存储canceltoken
window.$httpRequestList = new Map();
window.onbeforeunload = function () {
    document.documentElement.scrollTop = 0;  //ie下
    document.body.scrollTop = 0;  //非ie
}


chatHub()
app.mount('#app');
window._app = app;

